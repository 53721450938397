/**
  * This function loads the SVG representation of all
  * icons within the element provided. These icons
  * are in the shape of a <span class="icon icon-{iconName}"></span>,
  * where iconName is a string pointing to an element in the
  * assets/icons.json file. The SVGs are extracted from the Bootstrap
  * Icon Library.
  *
  * @param el The element to load icons in.
  */
export default function (el) {
  // replace all icons with their svg representation
  const iconsData = require('../assets/icons.json');
  el.querySelectorAll('.icon').forEach(icon => {
    icon.classList.remove('icon');
    let iconName = icon.classList[0];
    icon.classList.add('icon');

    try {
      icon.innerHTML = iconsData[iconName];
    } catch (e) {
      icon.innerHTML = '';
    }
  });
}

/**
  * This function removes all icon SVGs within the
  * element provided.
  *
  * @param el The element to unload icons in.
  */
export function unload (el) {
  el.querySelectorAll('.icon').forEach(icon => {
    icon.innerHTML = '';
  });
}
