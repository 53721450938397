<template>
  <div class="achievements-wrapper" id="achievements">
    <div class="achievements-inner-wrapper" id="achievements-inner">
      <h2 class="section-title mb-5">{{ data.title }}</h2>

      <div class="card shadow-lg mb-5" v-for="item in data.content" :key="item.title">
        <div class="card-body">
          <p class="card-title" v-html="item.title"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-code-slash" viewBox="0 0 16 16"><path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z"/></svg><span class="small-only"><br>Google Code-In Grand Prize Winner</span><span class="big-only">&nbsp;Google Code-In Grand Prize Winner</span></p>
          <p class="card-subtitle" v-html="item.subtitle">Dec 2018, Google</p>
          <p class="card-text" v-html="item.text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'achievements',
    props: {
      content: {
        type: String,
        default: null
      }
    },
    data() {
      // load locale data
      const data = JSON.parse(this.$props.content);
      if (data.success && data != null) return {
        data: data
      };
      throw new Error('Could not find data to render Achievements component.');
    }
  };
</script>

<style lang="scss">
  $lgray: #d3d3d3;
  $bgcolor: #191921;
  $bgcolor10: #303037;
  $bgcolor40: #75757a;
  $white: #eee;
  $dwhite: #ddd;
  $ddwhite: #bbb;
  $gold: #b7ac7f;

  #achievements {
    padding: 50px 10%;
    background-color: $bgcolor40;

    h2 {
      text-shadow: 2px 2px 3px $bgcolor10;
    }

    .card .card-title {
      padding-left: 68px;
      text-indent: -68px;

      @media only screen and (max-width: 768px) {
        padding-left: 0;
        text-indent: 0;
      }
    }
  }

  #achievements-inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    background-color: $bgcolor10 !important;
    text-align: left;

    a {
      text-decoration: none;
      color: $white;
      font-weight: bold;
    }

    a:hover {
      color: $gold;
    }

    .card-title {
      font-size: 36px;
      font-family: Inter, Helvetica, Arial, sans-serif;
      letter-spacing: -2px;
      font-weight: normal;
      color: $dwhite;

      svg {
        height: 56px;
        width: 56px;
        color: $lgray;
      }
    }

    .card-subtitle {
      @extend .card-text;
      color: $ddwhite !important;
      font-family: Inter, Helvetica, Arial, sans-serif !important;
      letter-spacing: -1px;
      font-size: 23px !important;
      font-weight: 500;
    }

    .card-text {
      color: $white;
      padding-left: 20px;
      padding-right: 100px;
      font-size: 18px;
      font-family: "EB Garamond", Georgia, Times, serif;
    }
  }

  @media only screen and (max-width: 768px) {
    #achievements {
      padding: 25px;
    }

    .card {
      text-align: center !important;

      .card-text {
        padding: 0 10px;
      }
    }

    .big-only {
      display: none;
    }
  }

  @media only screen and (min-width: 769px) {
    .small-only {
      display: none;
    }
  }
</style>
