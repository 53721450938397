<template>
  <div class="projects-wrapper" id="projects">
    <div class="projects-inner-wrapper" id="projects-inner">
      <h2 class="section-title mb-5">{{ data.title }}</h2>

      <div class="row" data-masonry='{ "percentPosition": true }'>
        <div class="col-lg-6 col-sm-12" v-for="item in data.content" :key="item.title">
          <div class="card shadow-lg mb-5">
            <img v-if="item.image" class="card-img-top" :src="getImgUrl(item.imgpath)" :alt="item.imgalt" :style="item.imgstyle ? item.imgstyle : ''">
            <div class="card-body">
              <p class="card-title" v-html="item.title"></p>
              <p class="card-subtitle" v-html="item.subtitle"></p>
              <p class="card-text" v-html="item.text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import MasonryLayout from 'masonry-layout'; // eslint-disable-line

  const images = require.context('../assets/', false, /\.png$/);

  export default {
    name: 'achievements',
    props: {
      content: {
        type: String,
        default: null
      }
    },
    methods: {
      getImgUrl (pet) {
        return pet ? images(`./${pet}`) : '';
      }
    },
    data() {
      // load locale data
      const data = JSON.parse(this.$props.content);
      if (data.success && data != null) return {
        data: data
      };
      throw new Error('Could not find data to render Projects component.');
    }
  };
</script>

<style lang="scss">
  $lgray: #d3d3d3;
  $bgcolor: #191921;
  $bgcolor10: #303037;
  $bgcolor40: #75757a;
  $white: #eee;
  $dwhite: #ddd;
  $ddwhite: #bbb;
  $gold: #b7ac7f;

  #projects {
    padding: 50px 10%;
    background-color: $bgcolor40;
    scroll-margin-top: 65px;

    h2 {
      text-shadow: 2px 2px 3px $bgcolor10;
    }

    .card, .card-title, .card-subtitle, .card-text {
      text-align: center;
      padding: none;
      text-indent: none;
    }

    img.card-img-top {
      height: 175px;
      object-fit: cover;
    }

    .card-text {
      padding-top: 10px;
    }

    .card-text, .card-subtitle {
      padding-left: 20px;
      padding-right: 20px;

      svg {
        height: 24px;
        width: 24px;
      }

      span.badge {
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }

  #projects-inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
