<template>
  <div class="navbar-wrapper">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand class="navbar-home-btn" to="/">{{ data.title }}</b-navbar-brand>

      <b-navbar-toggle class="navbar-toggler" target="nav-collapse"></b-navbar-toggle>
      <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
			</button> -->

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-for="item in data.items" :key="item[0]" class="nav-item" @click="scrollTo(item[1])">{{ item[0] }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'navbar',
    props: {
      content: {
        type: String,
        default: null
      }
    },
    data() {
      const data = JSON.parse(this.$props.content);

      if (data.success && data != null) return {
        data: data
      };
      throw new Error('Could not find data to render Navbar.');
    },
    methods: {
      /**
        * Scrolls to a given id.
        * @param {String} id the id of the element to scroll to
        */
      scrollTo: function (id) {
        let node = document.getElementById(id);
        let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if (width < 550) // in pixels
          node.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
        else
          node.scrollIntoView({behavior: 'smooth'});
      }
    }
  };
</script>

<style lang="scss" scoped>
  $white: #eee;
  $lgray: #ddd;
  $dark: #111827;

  .navbar-wrapper nav {
    background-color: $dark !important;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: bold;
    letter-spacing: -1px;
    position: fixed;
    width: 100%;
    z-index: 1;

    a {
      font-size: 30px;
      padding-left: 25px !important;
    }

    .navbar-home-btn {
      color: $white;
    }

    .nav-item a:hover {
      color: $lgray;
    }

    .navbar-toggler {
      float: right;
      border: none;
      padding-right: 0;

      :active, :focus {
        outline: none;
      }
    }

    .navbar-toggler-icon {
      width: 36px;
      height: 25px;
      background-image: none;
      position: relative;
      border-bottom: 1px solid #000;
      transition: all 300ms linear;
    }

    .navbar-toggler-icon:after,
    .navbar-toggler-icon:before {
      width: 36px;
      position: absolute;
      height: 25px;
      background-color: #000;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
    }
  }
</style>
