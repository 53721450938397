<template>
  <div class="contact-wrapper" id="contact">
    <div class="contact-inner-wrapper" id="contact-inner">
      <h2 class="section-title mb-5">{{ data.title }}</h2>

      <form name="contact" method="POST" action="https://getform.io/f/355a8d93-aa8c-4b18-ac21-bb773a8d833d">
        <div class="mb-3">
          <input type="text" name="name" class="form-control" id="contactName" :placeholder="data.name">
        </div>
        <div class="mb-3">
          <input type="email" name="email" class="form-control" id="contactEmail" aria-describedby="emailDisclaimer" :placeholder="data.email" required>
          <div id="emailDisclaimer" class="form-text">{{ data.emailDisclaimer }}</div>
        </div>
        <div class="mb-3">
          <textarea name="message" class="form-control" id="contactMessage" rows="4" :placeholder="data.message" required></textarea>
        </div>
        <div class="mb-3 form-check">
          <input type="checkbox" class="form-check-input" id="contactAntiSpam" required>
          <label class="form-check-label" for="contactAntiSpam">{{ data.antispam }}</label>
        </div>
        <button type="submit" class="btn btn-lg">{{ data.submit }}</button>
      </form>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'contact',
    props: {
      content: {
        type: String,
        default: null
      }
    },
    data() {
      // load locale data
      const data = JSON.parse(this.$props.content);
      if (data.success && data != null) return {
        data: data
      };
      throw new Error('Could not find data to render Contact component.');
    }
  };
</script>

<style lang="scss">
  $lgray: #d3d3d3;
  $bgcolor: #191921;
  $bgcolor10: #303037;
  $bgcolor20: #47474d;
  $bgcolor40: #75757a;
  $white: #eee;
  $dwhite: #ddd;
  $ddwhite: #bbb;
  $gold: #b7ac7f;

  #contact {
    padding: 50px 10%;
    background-color: $bgcolor;
    font-family: Inter, Helvetica, Arial, sans-serif;
    scroll-margin-top: 65px;

    form {
      max-width: 600px;
      text-align: left;
      color: $ddwhite;

      @media only screen and (max-width: 768px) {
        text-align: center;
      }

      input[type="text"], input[type="email"] {
        height: 50px;
        font-weight: bold;
      }

      input, textarea {
        background-color: $bgcolor10;
        border: none;
        color: $dwhite;

        :focus {
          border: 1px solid $white !important;
        }
      }

      button {
        background-color: $bgcolor20;
        border: none;
        width: 200px;
        color: $dwhite;
        font-weight: bold;
      }

      button:hover {
        color: $gold;
      }
    }
  }

  #contact-inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
