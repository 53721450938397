<template>
  <div class="gradient-change" v-bind:style="`height: ${this.$props.height}px; background-image: linear-gradient(${this.$props.from}, ${this.$props.to});`">
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'gradient',
    props: {
      height: { // height of the div
        type: Number,
        default: 150
      },
      from: { // the color to start the gradient from
        type: String,
        default: null
      },
      to: { // the color to end the gradient at
        type: String,
        default: null
      }
    }
  }
</script>
