<template>
  <div class="about-wrapper" id="about">
    <div class="row py-5 d-none d-md-flex" id="about-lg">
      <div class="col-3 profile-pic-wrapper mt-4">
        <img class="profile-pic-rounded" alt="Alberto Navalón" src="../assets/profile.jpeg">
      </div>
      <div class="col-1 contact-quick-wrapper">
        <table class="contact-quick">
          <tr>
            <!-- Email -->
            <td><a href="mailto:alberto@albertonl.com">
              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 7.5H37.5C39.5625 7.5 41.25 9.1875 41.25 11.25V33.75C41.25 35.8125 39.5625 37.5 37.5 37.5H7.5C5.4375 37.5 3.75 35.8125 3.75 33.75V11.25C3.75 9.1875 5.4375 7.5 7.5 7.5Z" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M41.25 11.25L22.5 24.375L3.75 11.25" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a></td>
          </tr>
          <tr>
            <!-- Github -->
            <td><a href="https://github.com/albertonl" target="_blank">
              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 41.25V33.9938C30.0703 33.0997 29.9495 32.2009 29.6457 31.3571C29.3418 30.5133 28.8617 29.7439 28.2375 29.1C34.125 28.4438 40.3125 26.2125 40.3125 15.975C40.312 13.3572 39.305 10.8398 37.5 8.94375C38.3547 6.65345 38.2943 4.12191 37.3312 1.87501C37.3312 1.87501 35.1188 1.21876 30 4.65001C25.7025 3.4853 21.1725 3.4853 16.875 4.65001C11.7562 1.21876 9.54375 1.87501 9.54375 1.87501C8.58071 4.12191 8.52027 6.65345 9.375 8.94375C7.55649 10.8538 6.54848 13.394 6.5625 16.0313C6.5625 26.1938 12.75 28.425 18.6375 29.1563C18.0206 29.7937 17.5449 30.5539 17.2412 31.3874C16.9375 32.2208 16.8128 33.1089 16.875 33.9938V41.25M16.875 35.625C7.5 38.4375 7.5 30.9375 3.75 30L16.875 35.625Z" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a></td>
          </tr>
          <tr>
            <!-- Instagram -->
            <td><a href="https://instagram.com/albertonl_" target="_blank">
              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.875 3.75H13.125C7.94733 3.75 3.75 7.94733 3.75 13.125V31.875C3.75 37.0527 7.94733 41.25 13.125 41.25H31.875C37.0527 41.25 41.25 37.0527 41.25 31.875V13.125C41.25 7.94733 37.0527 3.75 31.875 3.75Z" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30.0001 21.3188C30.2315 22.8792 29.9649 24.4729 29.2384 25.8732C28.5118 27.2734 27.3622 28.4089 25.9531 29.1182C24.544 29.8274 22.9471 30.0743 21.3897 29.8236C19.8322 29.573 18.3934 28.8377 17.2779 27.7222C16.1624 26.6067 15.4271 25.1679 15.1764 23.6104C14.9258 22.0529 15.1727 20.4561 15.8819 19.047C16.5912 17.6379 17.7267 16.4883 19.1269 15.7617C20.5272 15.0352 22.1209 14.7686 23.6813 15C25.273 15.236 26.7467 15.9778 27.8845 17.1156C29.0223 18.2534 29.764 19.727 30.0001 21.3188Z" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32.8125 12.1875H32.8312" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a></td>
          </tr>
        </table>
      </div>
      <div class="col-8 content-col">
        <h2 class="section-title">{{ data.title }}</h2>
        <div class="form-check form-switch mb-2">
          <b-form-checkbox v-model="extended" name="toggle-about-lg" @change="updateContentToggle()" size="lg" switch>
            {{ data.toggle }}
          </b-form-checkbox>
          <!-- <input class="form-check-input" type="checkbox" id="toggleAbout" @change="updateContentToggle()"/>
          <label class="form-check-label" for="toggleAbout">
            {{ data.toggle }}
          </label> -->
        </div>
        <p class="section-content" v-html="data.content[0]"></p>
        <p class="section-content d-none d-xl-block" v-html="data.content[1]"></p>
      </div>
      <div class="col-12 mt-3 pl-5">
        <p class="section-content d-block d-xl-none" v-html="data.content[1]"></p>
        <p class="section-content" v-for="item in data.content.slice(2)" :key="item" v-html="item"></p>
      </div>
    </div>
    <div class="py-5 d-sm-block d-md-none" id="about-sm">
      <h2 class="section-title">{{ data.title }}</h2>
      <img class="profile-pic-rounded" alt="Alberto Navalón" src="../assets/profile.jpeg">
      <div class="contact-quick-wrapper">
        <table class="contact-quick my-4">
          <tr>
            <!-- Email -->
            <td><a href="mailto:alberto@albertonl.com">
              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 7.5H37.5C39.5625 7.5 41.25 9.1875 41.25 11.25V33.75C41.25 35.8125 39.5625 37.5 37.5 37.5H7.5C5.4375 37.5 3.75 35.8125 3.75 33.75V11.25C3.75 9.1875 5.4375 7.5 7.5 7.5Z" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M41.25 11.25L22.5 24.375L3.75 11.25" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a></td>

            <!-- Github -->
            <td><a href="https://github.com/albertonl" target="_blank">
              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 41.25V33.9938C30.0703 33.0997 29.9495 32.2009 29.6457 31.3571C29.3418 30.5133 28.8617 29.7439 28.2375 29.1C34.125 28.4438 40.3125 26.2125 40.3125 15.975C40.312 13.3572 39.305 10.8398 37.5 8.94375C38.3547 6.65345 38.2943 4.12191 37.3312 1.87501C37.3312 1.87501 35.1188 1.21876 30 4.65001C25.7025 3.4853 21.1725 3.4853 16.875 4.65001C11.7562 1.21876 9.54375 1.87501 9.54375 1.87501C8.58071 4.12191 8.52027 6.65345 9.375 8.94375C7.55649 10.8538 6.54848 13.394 6.5625 16.0313C6.5625 26.1938 12.75 28.425 18.6375 29.1563C18.0206 29.7937 17.5449 30.5539 17.2412 31.3874C16.9375 32.2208 16.8128 33.1089 16.875 33.9938V41.25M16.875 35.625C7.5 38.4375 7.5 30.9375 3.75 30L16.875 35.625Z" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a></td>

            <!-- Instagram -->
            <td><a href="https://instagram.com/albertonl_" target="_blank">
              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.875 3.75H13.125C7.94733 3.75 3.75 7.94733 3.75 13.125V31.875C3.75 37.0527 7.94733 41.25 13.125 41.25H31.875C37.0527 41.25 41.25 37.0527 41.25 31.875V13.125C41.25 7.94733 37.0527 3.75 31.875 3.75Z" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30.0001 21.3188C30.2315 22.8792 29.9649 24.4729 29.2384 25.8732C28.5118 27.2734 27.3622 28.4089 25.9531 29.1182C24.544 29.8274 22.9471 30.0743 21.3897 29.8236C19.8322 29.573 18.3934 28.8377 17.2779 27.7222C16.1624 26.6067 15.4271 25.1679 15.1764 23.6104C14.9258 22.0529 15.1727 20.4561 15.8819 19.047C16.5912 17.6379 17.7267 16.4883 19.1269 15.7617C20.5272 15.0352 22.1209 14.7686 23.6813 15C25.273 15.236 26.7467 15.9778 27.8845 17.1156C29.0223 18.2534 29.764 19.727 30.0001 21.3188Z" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32.8125 12.1875H32.8312" stroke="#EEEEEE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a></td>
          </tr>
        </table>
      </div>
      <div class="form-check form-switch text-center mb-2">
        <b-form-checkbox v-model="extended" name="toggle-about-sm" @change="updateContentToggle()" size="lg" switch>
          {{ data.toggle }}
        </b-form-checkbox>
        <!-- <input class="form-check-input" type="checkbox" id="toggleAbout" @change="updateContentToggle()"/>
        <label class="form-check-label" for="toggleAbout">
          {{ data.toggle }}
        </label> -->
      </div>
      <p class="section-content text-center px-3" v-for="item in data.content" :key="item" v-html="item"></p>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'about',
    props: {
      short: {
        type: String,
        default: null
      },
      long: {
        type: String,
        default: null
      }
    },
    data() {
      // load locale data
      const data = JSON.parse(this.$props.short);
      if (data.success && data != null) return {
        data: data,
        extended: false,
        age: calculateCurrentAge()
      };
      throw new Error('Could not find data to render About component.');
    },
    mounted() {
      // show age
      document.querySelectorAll('.age').forEach(age => {
        age.innerHTML = this.age.toString();
      });
    },
    updated() {
      // reload icons
      const iconsData = require('../assets/icons.json');
      document.querySelectorAll('#about .icon').forEach(icon => {
        icon.classList.remove('icon');
        let iconName = icon.classList[0];
        icon.classList.add('icon');
        icon.classList.add('icon');

        try {
          icon.innerHTML = iconsData[iconName];
        } catch (e) {
          icon.innerHTML = '';
        }
      });

      // reload age
      document.querySelectorAll('.age').forEach(age => {
        age.innerHTML = this.age.toString();
      });
    },
    methods: {
      updateContentToggle: function () {
        if (this.extended) {
          this.data = JSON.parse(this.$props.long);
        } else {
          this.data = JSON.parse(this.$props.short);
        }
      }
    }

  };

  function calculateCurrentAge() {
    // calculate current age
    const birthday = new Date(2005, 9, 2);
    let today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    let m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  document.addEventListener('DOMContentLoaded', () => {
    // Resize columns on window resize
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 992) {
        document.querySelector('#about-lg .profile-pic-wrapper').classList.remove('col-3');
        document.querySelector('#about-lg .profile-pic-wrapper').classList.add('col-4');

        document.querySelector('#about-lg .content-col').classList.remove('col-8');
        document.querySelector('#about-lg .content-col').classList.add('col-7');
      } else {
        document.querySelector('#about-lg .profile-pic-wrapper').classList.add('col-3');
        document.querySelector('#about-lg .profile-pic-wrapper').classList.remove('col-4');

        document.querySelector('#about-lg .content-col').classList.add('col-8');
        document.querySelector('#about-lg .content-col').classList.remove('col-7');
      }
    });
  });
</script>

<style lang="scss">
  $lgray: #d3d3d3;
  $bgcolor: #191921;
  $white: #eee;
  $ddwhite: #ccc;
  $gold: #b7ac7f;

  #about {
    text-align: left;
    background-color: $bgcolor;
    scroll-margin-top: 65px;

    div.form-check.form-switch {
      z-index: 0;

      label {
        font-family: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 18px;
        color: $ddwhite;
        font-weight: bold;
        letter-spacing: -1px;
      }
    }
  }

  #about-lg {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  .profile-pic-rounded {
    display: block;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
  }

  .contact-quick-wrapper {
    display: table-cell;
    vertical-align: middle;

    @media only screen and (max-width: 768px) {
      width: 100vw !important;
    }
  }

  .contact-quick {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    text-align: center;

    a {
      text-decoration: none;
      color: $white;

      :hover {
        color: $gold;
      }
    }
  }

  .section-title {
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 56px;
    font-weight: bolder;
    letter-spacing: -3px;
    color: $lgray;
    text-align: left;

    @media only screen and (max-width: 768px) {
      text-align: center;
      font-size: 42px;
    }
  }

  .section-content {
    font-family: 'EB Garamond', Georgia, Times, serif;
    font-size: 20px;
    color: $white;

    @media only screen and (min-width: 769px) {
      padding-right: 75px;
    }

    span {
      display: inline-block;
    }

    a {
      text-decoration: none;
      color: $white;

      :hover {
        color: $gold;
      }
    }
  }
</style>
