<template>
  <div id="app">
    <Navbar :content="renderJSON('navbar')"/>
    <Banner :content="renderJSON('banner')"/>
    <About :short="renderJSON('aboutShort')" :long="renderJSON('about')"/>
    <!-- <About :content="renderJSON('about')"/> -->
    <Gradient from="#191921" to="#75757a"/>
    <Achievements :content="renderJSON('achievements')"/>
    <Gradient from="#75757a" to="#191921"/>
    <Languages :content="renderJSON('languages')"/>
    <Gradient from="#191921" to="#75757a"/>
    <Projects :content="renderJSON('projects')"/>
    <Gradient from="#75757a" to="#191921"/>
    <Contact :content="renderJSON('contact')"/>
    <albertonl-footer :content="renderJSON('footer')" :langs="languages" :currlang="currLanguage"/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Banner from './components/Banner.vue'
import About from './components/About.vue'
import Achievements from './components/Achievements.vue'
import Languages from './components/Languages.vue'
import Projects from './components/Projects.vue'
import Contact from './components/Contact.vue'
import Gradient from './components/GradientChange.vue'
import AlbertonlFooter from './components/Footer.vue'

import IconLoader from './plugins/icon-loader.js'

const availableLanguages = ['en', 'es'];

var localeData = {};
var currLanguage = 'en';
if ((!localStorage.getItem('locale') || !availableLanguages.includes(localStorage.getItem('locale'))) && availableLanguages.includes(navigator.language.slice(0,2)))
  localStorage.setItem('locale', navigator.language.slice(0,2));
if (!localStorage.getItem('locale') || !availableLanguages.includes(localStorage.getItem('locale'))) {
  localeData = require('./assets/locale/strings.en.json');
} else {
  localeData = require(`./assets/locale/strings.${localStorage.getItem('locale')}.json`);
  currLanguage = localStorage.getItem('locale');
}

export default {
  name: 'App',
  components: {
    Navbar,
    Banner,
    About,
    Achievements,
    Languages,
    Projects,
    Contact,
    Gradient,
    AlbertonlFooter
  },
  data() {
    return {
      cubingData: JSON.stringify(require('./assets/data/cubing-stats.json')),
      languages: JSON.stringify(require('./assets/data/languages.json')),
      currLanguage: currLanguage
    };
  },
  methods: {
    /**
      Returns the corresponding content extracted from a JSON file
      in the shape of a regular String.

      @param item {String} The item to extract the data from.
      @returns {String} The formatted JSON string.
    */
    renderJSON: function (item) {
      let data = undefined;
      try {
        data = localeData[item];
        data.success = true;
      } catch (e) {
        data = {
          success: false,
          error: e
        };
      }

      return JSON.stringify(data);
    }
  },
  mounted() {
    IconLoader(document);
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,700;1,400&display=swap');

  html, body {
    overflow-x: hidden;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
