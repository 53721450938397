<template>
  <div class="languages-wrapper pt-5" id="languages">
    <table class="languages-title big-only">
      <tr>
        <td class="section-title languages-title-main">{{ data.title_main }}</td>
        <td class="section-title languages-title-secondary">{{ data.title_secondary }}</td>
      </tr>
    </table>
    <p class="section-title small-only text-center p-3">
      {{ data.title_main }}<br>
      <span class="languages-title-secondary-small">{{ data.title_secondary }}</span>
    </p>

    <div class="row languages-list py-5">
      <div class="col-lg-2 col-sm-4 col-xs-12 my-1 languages-item" v-for="(item, index) in data.langlist" :key="item.translated">
        <div class="d-none d-sm-block">
          <img :src="item.flag" :alt="item.alt">
          <p class="language-translated pt-3" v-if="item.translated">{{ item.translated }}</p>
          <p class="language-empty big-only" v-else>(none)</p>
          <p class="language-original" v-if="item.original">{{ item.original }}</p>
          <p class="language-empty big-only" v-else>(none)</p>
          <p class="language-status" v-if="item.status" :style="'color: ' + item.color + ';'">{{ item.status }}</p>
          <p class="language-empty big-only" v-else>(none)</p>
        </div>
        <div class="d-xs-block d-sm-none">
          <table>
            <tr>
              <td class="language-flag-cell">
                <img :src="item.flag" :alt="item.alt">
              </td>
              <td class="language-info-cell">
                <p class="language-translated" v-if="item.translated">{{ item.translated }}</p>
                <p class="language-empty big-only" v-else>(none)</p>
                <p class="language-original" v-if="item.original">{{ item.original }}</p>
                <p class="language-empty big-only" v-else>(none)</p>
                <p class="language-status" v-if="item.status" :style="'color: ' + item.color + ';'">{{ item.status }}</p>
                <p class="language-empty big-only" v-else>(none)</p>
              </td>
            </tr>
          </table>
          <hr v-if="index !== data.langlist.length - 1">
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'languages',
    props: {
      content: {
        type: String,
        default: null
      }
    },
    data() {
      // load locale data
      const data = JSON.parse(this.$props.content);

      if (data.success && data != null) return {
        data: data
      };
      throw new Error('Could not find data to render Languages component.');
    }
  };
</script>

<style lang="scss">
  $lgray: #d3d3d3;
  $bgcolor: #191921;
  $bgcolor10: #303037;
  $white: #eee;
  $dwhite: #ddd;
  $ddwhite: #bbb;
  $dddwhite: #aaa;
  $gold: #b7ac7f;

  #languages {
    background-color: $bgcolor;

    table.languages-title {
      margin: 0 5%;

      td {
        vertical-align: top;
        text-align: left;
        line-height: 65px;

        @media only screen and (max-width: 992px) {
          font-size: 2.5rem;
        }
      }

      .languages-title-main {
        padding-left: 20px;
        padding-right: 10px;
      }

      .languages-title-secondary {
        padding-right: 20px;
        color: $dddwhite;
      }
    }

    .languages-title-secondary-small {
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: -1px;
      color: $dddwhite;
    }

    .languages-list {
      table {
        margin-left: auto;
        margin-right: auto;

        td.language-flag-cell {
          width: 25%;
        }

        td.language-info-cell {
          width: 50%;
        }
      }

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        object-position: 25%;
      }

      p {
        font-family: Inter, Helvetica, Arial, sans-serif;
        letter-spacing: -1px;
        font-size: 1.2rem;
      }

      .language-translated {
        font-weight: bold;
        color: $white;
      }

      .language-original {
        color: $white;
        font-weight: normal;
      }

      .language-empty {
        color: $bgcolor;
        cursor: default;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      hr {
        background-color: $dddwhite;
        width: 70%;
      }
    }
  }
</style>
