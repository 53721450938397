<template>
  <div class="footer-wrapper" id="footer">
    <footer class="footer-inner-wrapper row" id="footer-inner">
      <div class="mb-2 col-md-6 col-sm-12 text-left footer-left">
        <p>&copy; Copyright {{ currYear }}: <a href="https://albertonl.com">albertonl.com</a></p>
      </div>
      <div class="mb-2 col-md-6 col-sm-12 text-right footer-right">
        <p v-html="data.message">Made with &#10084; in &#127466;</p>
        <select name="language" id="language" @change="changeLanguage()">
          <option v-for="item in languages" :key="item.code" :value="item.code" :disabled="item.disabled">{{ item.name }}</option>
        </select>
      </div>
    </footer>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'albertonlFooter',
    props: {
      content: {
        type: String,
        default: null
      },
      langs: {
        type: String,
        default: null
      },
      currlang: {
        type: String,
        default: 'en'
      }
    },
    methods: {
      changeLanguage: function () {
        const lang = document.getElementById('language').value;
        localStorage.setItem('locale', lang);
        window.location.reload(false);
      }
    },
    mounted() {
      document.querySelectorAll('#language option').forEach(option => {
        if (option.value === this.$props.currlang)
          option.selected = true;
      })
    },
    data() {
      // load locale data
      const data = JSON.parse(this.$props.content);
      const languages = JSON.parse(this.$props.langs);
      if (data.success && data != null) return {
        data: data,
        languages: languages,
        currYear: new Date().getFullYear() // current year for copyright notice
      };
      throw new Error('Could not find data to render Footer component.');
    }
  };
</script>

<style lang="scss">
  $lgray: #d3d3d3;
  $bgcolor: #191921;
  $bgcolor10: #303037;
  $bgcolor20: #47474d;
  $bgcolor40: #75757a;
  $white: #eee;
  $dwhite: #ddd;
  $ddwhite: #bbb;
  $gold: #b7ac7f;

  #footer {
    padding: 50px 10%;
    background-color: $bgcolor;
    font-family: Inter, Helvetica, Arial, sans-serif;
    color: $dwhite;

    a {
      font-weight: bold;
      color: $white;
    }

    select {
      font-family: Inter, Helvetica, Arial, sans-serif;
      color: $ddwhite;
      background: none;
      border: none;

      :focus {
        border: none;
      }
    }
  }

  #footer-inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .no-font {
    font-family: none !important;
  }

  @media only screen and (max-width: 768px) {
    #footer .text-left, #footer .text-right {
      text-align: center !important;
    }
  }
</style>
