<template>
  <div id="banner" class="banner-wrapper">
    <div class="d-none d-md-block">
      <table class="banner">
        <tr>
          <td class="banner-img-wrapper">
            <!-- <img class="banner-img" src="../assets/banner.jpg" alt="Banner portrait"> -->
          </td>
          <td class="banner-content">
            <p class="banner-overtitle">{{ data.overtitle }}</p>
            <h1 class="banner-title">{{ data.title }}</h1>
            <img class="banner-logo" src="../assets/logo.png" alt="Banner logo">
            <span class="banner-logo-text">{{ data.name }}</span>

            <div class="banner-chevron chevron-down">
              <span id="scrollToAbout" @click="scrollTo('about')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="d-block d-md-none banner-small">
      <p class="banner-overtitle">{{ data.overtitle }}</p>
      <h1 class="banner-title">{{ data.title }}</h1>
      <div class="banner-small-bottom">
        <div class="d-inline-block">
          <img class="banner-logo" src="../assets/logo-nobackground.png" alt="Banner logo">
          <span class="banner-logo-text">{{ data.name }}</span>

          <div class="banner-chevron chevron-down">
            <span id="scrollToAbout" @click="scrollTo('about')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'banner',
    props: {
      content: {
        type: String,
        default: null
      }
    },
    data() {
      const data = JSON.parse(this.$props.content);
      if (data.success && data != null) return {
        data: data
      };
      throw new Error('Could not find data to render Banner component.');
    },
    methods: {
      /**
        * Scrolls to a given id.
        * @param {String} id the id of the element to scroll to
        */
      scrollTo: function (id) {
        let node = document.getElementById(id);
        let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if (width < 550) // in pixels
          node.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
        else
          node.scrollIntoView({behavior: 'smooth'});
      }
    }
  };
</script>

<style lang="scss" scoped>
  $lgray: #d3d3d3;
  $opdgray: #aaadad;
  $bgcolor: #191921;
  $bgcolor10: #303037;
  $bgcolor40: #75757a;
  $white: #eee;
  $dwhite: #ddd;
  $ddwhite: #bbb;
  $gold: #b7ac7f;
  $black: #111111;

  .banner-wrapper {
    height: 100vh !important;
    width: 100vw !important;

    background-color: $bgcolor;

    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: bold;
    letter-spacing: -1px;

    table {
      height: 100vh;
      width: 100vw;

      @media only screen and (max-width: 992px) {
        p.banner-overtitle {
          font-size: 27px !important;
        }

        h1.banner-title {
          font-size: 61.5px !important;
          margin-bottom: 50px !important;
        }

        img.banner-logo {
          height: 150px !important;
          width: 150px !important;
        }

        span.banner-logo-text {
          font-size: 36px !important;
        }
      }

      p.banner-overtitle {
        padding-top: 100px;
        font-size: 36px;
        letter-spacing: -2px;
        color: $opdgray;
      }

      h1.banner-title {
        font-size: 82px;
        font-weight: bold;
        letter-spacing: -3px;
        color: $white;
        margin-bottom: 75px;
      }

      img.banner-logo {
        height: 200px;
        width: 200px;
      }

      span.banner-logo-text {
        font-size: 48px;
        color: $dwhite;
        font-weight: normal;
        font-family: 'EB Garamond';
        letter-spacing: none;
      }

      .banner-chevron {
        width: 100%;
        bottom: 0;
        margin-bottom: 10px;
        text-align: center;

        svg {
          cursor: pointer;
          height: 65px;
          width: 65px;
          color: $ddwhite;
        }
      }
    }
  }

  td.banner-img-wrapper {
    height: 100%;
    width: 40%;

    @media only screen and (max-width: 1100px) {
      width: 45%;
    }

    background-image: url('../assets/banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    /* img.banner-img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    } */
  }

  .banner-small {
    height: 100%;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: bold;
    letter-spacing: -1px;
    padding-top: 125px;
    position: relative;
    /* text-align: left;
    padding-top: 50px;
    padding-left: 25px; */

    background: linear-gradient(rgba(25,25,33,.65), rgba(25,25,33,.65)), url('../assets/banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .banner-small-bottom {
      position: absolute;
      bottom: 25px;
      width: 100%;
      text-align: center;

      .banner-chevron {
        width: 100%;
        bottom: 0;
        margin-bottom: 10px;
        text-align: center;

        svg {
          height: 50px;
          width: 50px;
          color: $ddwhite;
          cursor: pointer;
        }
      }
    }

    @media only screen and (max-width: 350px) {
      img.banner-logo {
        height: 75px !important;
        width: 75px !important;
      }

      span.banner-logo-text {
        font-size: 24px !important;
      }

      p.banner-overtitle {
        font-size: 18px !important;
      }

      h1.banner-title {
        font-size: 42px !important;
        letter-spacing: -1px;
      }
    }

    img.banner-logo {
      height: 100px;
      width: 100px;
    }

    span.banner-logo-text {
      font-family: 'EB Garamond';
      font-size: 32px;
      font-weight: normal;
      color: $dwhite;
    }

    p.banner-overtitle {
      font-size: 25px;
      color: $opdgray;
    }

    h1.banner-title {
      font-size: 56px;
      font-weight: bold;
      letter-spacing: -2px;
      color: $white;
    }

    .banner-img-wrapper {
      height: 50%;
      width: 100%;
      img.banner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
</style>
